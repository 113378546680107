import React, { useState, Fragment, useRef } from "react";
import { NavLink } from "react-router-dom";
import Footer from "../../component/Other/Footer";
import Navbar from "../../component/Other/Navbar";
import About from "../../component/Section/About";
import Blog from "../../component/Section/Blog";
import Contact from "../../component/Section/Contact";
import Service from "../../component/Section/Service";
import SliderConstruction from "../../component/Slider/SliderConstruction";

const Index = (props) => {
  const service = [
    {
      img: "./../images/Image (4).png",
      title: "Petroleum & Gas Energy",
      desc: "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit."
    },
    {
      img: "./../images/Image (5).png",
      title: "Construction & Engineering",
      desc: "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit."
    },
    {
      img: "./../images/Image (6).png",
      title: "Auto & Mechanical Engineering",
      desc: "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit."
    },
  ]

  const blog = [
    {
      img: "./../images/bg (1).png",
      tag: "oil & gas",
      date: "29 Nov 2022",
      title: "Cargo flow through better supply chain visibility, control.",
      desc: "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit."
    },
    {
      img: "./../images/bg (3).png",
      tag: "oil & gas",
      date: "29 Nov 2022",
      title: "Cargo flow through better supply chain visibility, control.",
      desc: "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit."
    },
    {
      img: "./../images/bg (2).png",
      tag: "oil & gas",
      date: "29 Nov 2022",
      title: "Cargo flow through better supply chain visibility, control.",
      desc: "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit."
    },
  ]
  return (
    <Fragment>
      <div className="overflow-hidden">
        <div className="position-relative">
          <img src="./../images/Bg.png" className="images__wrap-head" alt="" />
          <div className="wrapper__bg-head"></div>
          <Navbar />
          <section className="position-relative z-2">
            <div className="container">
              <div className="text-center">
                <h1 className="semi-bold font__size--90 text__90-1024 text__90-md text__90-sm text__90-xs  color__white text-uppercase mb-0">We Provide high quality <br className="d-none d-sm-block" /> Building Materials</h1>
                <p className="medium roboto font__size--16 text__16-1024 color__white my-4">Lorem ipsum dolor sit amet consectetur. Suspendisse ultrices tortor <br className="d-none d-sm-block" /> vel lectus. Tincidunt mauris eget volutpat nunc.</p>

                <div className="d-flex flex-wrap flex-sm-nowrap align-items-center justify-content-center">
                  <a href="#!" className="btn__mm-w-60 semi-bold rounded-0 font__size--14 text__14-1024 btn btn__orange shadow color__white text-uppercase">Discover More</a>
                  <NavLink
                    to="/service" className="btn__mm-w-60  semi-bold font__size--14 text__14-1024 btn btn__outlined--white color__white no__opacity h__black rounded-0 shadow text-uppercase mt-3 mt-sm-0 ml-sm-4">our service</NavLink>
                </div>
              </div>
            </div>
          </section>
        </div>

        <About />

        <Service service={service} />

        <section className="overflow-hidden">
          <div className="text-center mb-5">
            <p className="semi-bold font__size--14 text__14-1024 color__orange text-uppercase">our work</p>
            <h3 className="bold font__size--58 text__50-1024 text__50-sm text__50-xs text-uppercase">Construction Projects</h3>
          </div>
          <SliderConstruction />
        </section>

        <Blog blog={blog} title={"news & articles"} top={"latest blog"} />

        <Contact />

        <Footer />
      </div >
    </Fragment >
  );
};

export default Index;
